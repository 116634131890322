import classNames from 'classnames';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import React from 'react';

import BlockBuilder from '../../BlockBuilder';
import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionCard.module.css';

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 265px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionCard = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    imageUrl,
    appearance,
    callToAction,
    blocks,
    customFooter,
    isInsideContainer,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      <div className={css.row}>
        {hasBlocks ? (
          <div
            className={classNames(getColumnCSS(numColumns), css.container, {
              [css.noSidePaddings]: isInsideContainer,
            })}
          >
            <BlockBuilder
              ctaButtonClass={defaultClasses.ctaButton}
              blocks={blocks}
              sectionId={sectionId}
              responsiveImageSizes={getResponsiveImageSizes(numColumns)}
              options={options}
            />
          </div>
        ) : null}

        {imageUrl && <div className={css.icon}>
          <img src={imageUrl} />
        </div>}
      </div>

      {customFooter && <div className={css.footer}>
        <Field className={css.footerAction} data={{
          fieldType: 'internalButtonLink',
          content: 'Browse Listings',
          href: '/s',
        }} />
        <Field className={classNames(css.footerAction, css.primary)} data={{
          fieldType: 'internalButtonLink',
          content: 'New Medical Equipment',
          href: '/p/marketplace_new-equipment',
        }} />
        <Field className={classNames(css.footerAction, css.secondary)} data={{
          fieldType: 'internalButtonLink',
          content: 'Used Medical Equipment',
          href: '/p/marketplace_sustainable-products',
        }} />
      </div>}

    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCard.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  imageUrl: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  customFooter: false,
  isInsideContainer: false,
  options: null,
};

SectionCard.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  imageUrl: string,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  customFooter: bool,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionCard;
