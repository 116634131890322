import classNames from 'classnames';
import { object, string } from 'prop-types';
import React from 'react';

import Field from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockCTA.module.css';

// This element can be used to wrap some common styles and features,
// if there are multiple blockTypes,
const BlockCTA = props => {
    const {
        blockId,
        style,
        text,
        callToAction,
    } = props;
    let styleClass;
    switch (style) {
        case 'primary': styleClass = css.primary;
            break;
        case 'secondary': styleClass = css.secondary;
            break;
        default: throw Error(`Unsupported BlockCTA class ${style}`);
    }

    return (
        <BlockContainer id={blockId}>
            <div className={css.container}>
                <div className={css.label}><Field data={text}></Field></div>
                <span className={css.arrow}>›</span>
                <Field className={classNames(css.action, styleClass)} data={callToAction} />
            </div>
        </BlockContainer>);
};

BlockCTA.defaultProps = {
    blockId: null,
    rootClassName: null,
    className: null,
    style: 'primary',
    as: 'div',
    text: null,
    ctaButtonClass: null,
    callToAction: null,
};

BlockCTA.propTypes = {
    blockId: string,
    rootClassName: string,
    className: string,
    style: string,
    as: string,
    text: object,
    ctaButtonClass: string,
    callToAction: object,
};

export default BlockCTA;
