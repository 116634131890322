import classNames from 'classnames';
import { object, string } from 'prop-types';
import React from 'react';

import Field from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockChip.module.css';

// This element can be used to wrap some common styles and features,
// if there are multiple blockTypes,
const BlockChip = props => {
    const {
        blockId,
        style,
        media,
        title,
        callToAction,
    } = props;
    let styleClass;
    switch (style) {
        case 'primary': styleClass = css.primary;
            break;
        case 'secondary': styleClass = css.secondary;
            break;
        default: throw Error(`Unsupported BlockCTA class ${style}`);
    }

    return (
        <BlockContainer id={blockId} className={classNames(css.container, styleClass)}>
            <Field className={css.row} data={callToAction}>
                <Field className={css.icon} data={media} />
                <Field className={css.title} data={{ ...title, fieldType: 'text' }} />
            </Field>
        </BlockContainer>);
};

BlockChip.defaultProps = {
    blockId: null,
    rootClassName: null,
    className: null,
    style: 'primary',
    as: 'div',
    title: null,
    text: null,
    ctaButtonClass: null,
    callToAction: null,
};

BlockChip.propTypes = {
    blockId: string,
    rootClassName: string,
    className: string,
    style: string,
    as: string,
    title: object,
    text: object,
    ctaButtonClass: string,
    callToAction: object,
};

export default BlockChip;
