import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import React, { Component } from 'react';

import * as sharetribeSdk from 'sharetribe-flex-sdk';

import classNames from 'classnames';
import { IconSpinner, ListingCard } from '../../../../components';
import css from './SectionMarketplace.module.css';

const CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
class SectionMarketplace extends Component {
  constructor(props) {
    super(props);
    this.sdk = sharetribeSdk.createInstance({ clientId: CLIENT_ID });
    this.state = {
      data: [],
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    const status = this.props.listingStatus;
    this.fetchData({ status });
  }

  // Query the most recent 8 listings with the [status: new | used]
  async fetchData({ status }) {
    try {
      const result = await this.sdk.listings.query({
        perPage: 8,
        sort: 'createdAt',
        include: ['author', 'images'],
        'pub_status': status,
        'fields.listing': ['title', 'geolocation', 'price', 'publicData.listingType', 'publicData.status'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.scaled-small', 'variants.scaled-medium', 'variants.listing-card', 'variants.listing-card-2x'],
        'imageVariant.listing-card': 'w:400;h:533;fit:crop',
        'imageVariant.listing-card-2x': 'w:800;h:1067;fit:crop',
        'limit.images': 1,
      });

      const { data, included } = result.data;
      if (data.length === 0) {
        this.setState({ data: [], loading: false, error: null });
        return;
      }

      const authorRefs = included.filter((x) => x.type == 'user');
      const imageRefs = included.filter((x) => x.type == 'image');

      const listings = [];
      for (let d of data) {
        const listing = {
          type: 'listing',
          id: d.id,
          attributes: d.attributes,
          author: null,
          images: [],
        };

        const authorId = d.relationships.author.data.id.uuid;
        const author = authorRefs.find((x) => x.id.uuid == authorId);
        listing.author = {
          type: 'user',
          id: author.id,
          attributes: author.attributes
        }

        const imageIds = d.relationships.images.data.map((x) => x.id.uuid);
        for (let ref of imageRefs) {
          if (imageIds.includes(ref.id.uuid)) {
            const img = {
              id: ref.id,
              type: 'image',
              attributes: ref.attributes,
            }
            listing.images.push(img);
          }
        }

        listings.push(listing);
      }

      this.setState({ data: listings, loading: false, error: null });
    } catch (error) {
      console.error(error);
      this.setState({ error: error.message, loading: false });
    }
  }

  render() {
    const {
      sectionId,
      className,
    } = this.props;

    return (
      <div
        id={sectionId}
        className={classNames(className, css.container)}
      >
        {this.state.loading &&
          <div className={css.loader}> <IconSpinner className={css.loader} /> </div>
        }

        {(!this.state.loading && this.state.data.length == 0) &&
          <div>No listings available.</div>
        }

        {this.state.data.length > 0 &&
          <div className={css.listings}>
            {this.state.data.map((listing, i) =>
              <ListingCard
                key={`listing.id_${i}`}
                listing={{ ...listing, id: listing.id }}
              />
            )}
          </div>
        }
      </div>
    );
  }

}

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionMarketplace.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionMarketplace.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionMarketplace;
