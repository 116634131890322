import { object, string } from 'prop-types';
import React from 'react';

import Field from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockFeature.module.css';

// This element can be used to wrap some common styles and features,
// if there are multiple blockTypes,
const BlockFeature = props => {
    const {
        blockId,
        rootClassName,
        className,
        as,
        media,
        title,
        text,
        ctaButtonClass,
        callToAction,
    } = props;

    return (
        <BlockContainer id={blockId}>
            <div className={css.container}>
                <Field className={css.icon} data={media} />
                <div className={css.col}>
                    <Field className={css.title} data={title} />
                    <p className={css.content}>{text.content}</p>
                </div>
            </div>
        </BlockContainer >);
};

BlockFeature.defaultProps = {
    blockId: null,
    rootClassName: null,
    className: null,
    as: 'div',
    title: null,
    text: null,
    ctaButtonClass: null,
    callToAction: null,
};

BlockFeature.propTypes = {
    blockId: string,
    rootClassName: string,
    className: string,
    as: string,
    title: object,
    text: object,
    ctaButtonClass: string,
    callToAction: object,
};

export default BlockFeature;
