import { object, string } from 'prop-types';
import React from 'react';

import Field from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockText.module.css';

// This element can be used to wrap some common styles and features,
// if there are multiple blockTypes,
const BlockText = props => {
    const {
        blockId,
        title,
        text,
    } = props;

    return (
        <BlockContainer id={blockId}>
            <div className={css.container}>
                <Field className={css.title} data={title} />
                <span className={css.blank}>&nbsp;</span>
                <p className={css.content}>{text.content} </p>
            </div>
        </BlockContainer >);
};

BlockText.defaultProps = {
    blockId: null,
    rootClassName: null,
    className: null,
    as: 'div',
    title: null,
    text: null,
    ctaButtonClass: null,
    callToAction: null,
};

BlockText.propTypes = {
    blockId: string,
    rootClassName: string,
    className: string,
    as: string,
    title: object,
    text: object,
    ctaButtonClass: string,
    callToAction: object,
};

export default BlockText;
